import React from 'react';
import LoginPage from './LoginPage.js';
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import history from './history';
import ManageUsersPage from './ManageUsersPage.js';
import UserSignUpPage from './UserSignUpPage.js';
import FilesPage from './FilesPage.js';
import ForgotPasswordPage from './ForgotPasswordPage.js';

export default class Routes extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={LoginPage} />
          <Route path="/Login" component={LoginPage} />
          <Route path="/ManageUsers" component={ManageUsersPage} />
          <Route path="/Register" component={UserSignUpPage} />
          <Route path="/Files" component={FilesPage} />
          <Route path="/ForgotPassword" component={ForgotPasswordPage} />
        </Switch>
      </Router>
    )
  }
}
