import React from 'react';
import store from 'store';
import '../styles/CustomerPortal.css';
import {
  Redirect,
  withRouter,
} from "react-router-dom";
import NavigationBar from './NavigationBar.js';
import PortalFooter from './PortalFooter.js';
import Table from "./Table.js";
import CheckTree from "./CheckTree.js";
import Expandable from "./Expandable.js";
import 'rsuite/dist/styles/rsuite-default.css';
import { AutoComplete, Button, ButtonToolbar, ControlLabel, DatePicker, Form, FormControl, FormGroup, InputPicker, Modal, Schema } from 'rsuite';

// Add the Expire plugin to store.js:
var expirePlugin = require('store/plugins/expire');
store.addPlugin(expirePlugin);
const ONE_HOUR_MS = 60*60*1000;

class FilesPage extends React.Component {
  // Constructor which receives our props
  constructor(props) {
    super(props);
    const { DateType, NumberType, StringType } = Schema.Types;
    const today = new Date();
    today.setHours(0,0,0,0);
    const endOfDay = new Date();
    endOfDay.setHours(23,59,59,0);
    const model = Schema.Model({
      product: NumberType().isRequired('This field is required.'),
      productVersion: StringType().isRequired('This field is required.'),
      fileType: StringType().isRequired('This field is required.'),
      company: StringType(),
      releaseDate: DateType().isRequired('This field is required.')
    });
    // Next we set our state
    this.state = {
      formValue: {
        product: '',
        productVersion: '',
        fileType: '',
        company: '',
        releaseDate: today,
      },
      date: today,
      endOfDate: endOfDay,
      formError: {},
      model: model,
      stateInfo: {},
      password: '',
      token: '',
      name: '',
      email: '',
      permissions: '',
      newFile: null,
      loaded: 0,
      showUploadFilePopup: false,
      popupOverflow: true,
      popupRows: 0,
      submitButtonStatus: true,
      filesList: [],
      fileSearch: '',
      fileProductFilterList: [],
      fileProductFilter: [],
      fileTypeFilterList: [],
      fileTypeFilter: [],
      fileStartDateFilter: '',
      fileEndDateFilter: '',
      companyList: [],
      fileInfo: {},
      downloadButtonStatus: true,
      showSharePopup: false,
      permissionPopupRows: 0,
      userTreeStructure: [],
      submitPermissionButtonStatus: true,
      openPermissionPopupButtonStatus: true,
      selectedUserInfo: {},
      listOfUsersWithFilePermission: [],
      fileInfoString: '',
      grantPermissionForm: {
        user: {
          email: '',
        },
        file: {
          id: '',
          fileName: '',
        },
        endDate: endOfDay,
      },
      productTemplate: 
      {
        id: '',
        labelName: '',
        children: [], // The children will be generated using the productVersionTemplate.
      },
      productVersionTemplate:
      {
        id: '',
        labelName: '', // Product Version Number
        children: [], // Files accociated with the product version number
      },
      fileTemplate: {
        id: '', // Unique FileID used by the backend Server to identify the file.
        fileName: '', // the file name
        releaseDate: '',
        endDate: '',
        fileType: '',
        product: '',
        productVersion: '',
        company: '',
      },
      companyTemplate: 
      {
        id: '',
        company: '',
        children: [], // The children will be generated using the user template.
      },
      userTemplate: // Template to store/display information about a user in the user table.
      {
        id: '',
        name: '',
        email: '',
        companyName: '',
        role: '',
      },
    }

    this.handleLogout = this.handleLogout.bind(this);
    this.handleManageUsers = this.handleManageUsers.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleFilesPage = this.handleFilesPage.bind(this);
    this.closeUploadFilePopup = this.closeUploadFilePopup.bind(this);
    this.resetRows = this.resetRows.bind(this);
    this.openUploadFilePopup = this.openUploadFilePopup.bind(this);
    this.onNewFileChangeHandler = this.onNewFileChangeHandler.bind(this);
    this.handleFilesList = this.handleFilesList.bind(this);
    this.handleFileDownload = this.handleFileDownload.bind(this);
    this.updateStateExpiration = this.updateStateExpiration.bind(this);
    this.onFilePropertiesChange = this.onFilePropertiesChange.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.openPermissionPopup = this.openPermissionPopup.bind(this);
    this.resetPermissionRows = this.resetPermissionRows.bind(this);
    this.closePermissionPopup = this.closePermissionPopup.bind(this);
    this.handleOpenPermissionPopup = this.handleOpenPermissionPopup.bind(this);
    this.updatePermissionEndDate = this.updatePermissionEndDate.bind(this);
    this.submitNewFilePermission = this.submitNewFilePermission.bind(this);
    this.getIndividualPermissionInfo = this.getIndividualPermissionInfo.bind(this);
    this.getProductList = this.getProductList.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
  }

  submitNewFilePermission(grantPermissionForm) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;
    var token = that.state.stateInfo.token;

    // Attempt to get the list of files that the current user has permission to download.
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/file/permit`, {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
      "body": JSON.stringify(grantPermissionForm),
    })
    .then(function(response) {
      if (response.ok) {
        // Alert user of success.
        alert("File Shared");
        // Close pop-up.
        that.closePermissionPopup();
      }
      else {
        throw response.json();
      }
    })
    .catch(function(err) {
      console.log(err);
      alert("Share Failed.");
    });
  }

  updatePermissionEndDate(newEndDate) {
    newEndDate.setHours(23,59,59,0);  // Set the permission to end after the last second of the last minute of the last hour of the day.
    var updatedGrantPermissionForm = this.state.grantPermissionForm;
    updatedGrantPermissionForm.endDate = newEndDate;
    this.setState({ grantPermissionForm: updatedGrantPermissionForm });
  }

  deleteFile(fileId) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;

    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/file/remove/${fileId}`, {
      "method": "DELETE",
      "headers": {
        "Content": "text/plain",
        "Content-Type": "text/plain",
        "Authorization": "Bearer " + this.state.token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
    })
    .then(function(response) {
      if (response.ok) {
        alert("File successfully deleted");
        that.handleFilesList(that.state.email, that.state.token);
      } else {
        throw response;
      }
    })
    .catch(function(err) {
      console.error(err);
      alert('File delete failed');
    });
  }

  handleDeleteFile(rowData) {
    let doDelete = window.confirm("Are you sure you want to delete " + rowData.values.fileName);
    if (doDelete) {
      this.deleteFile(rowData.values.id);
    } // else clicked cancel, do not delete
  }

  handleOpenPermissionPopup(rowData) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;
    var token = (that.state.stateInfo.token === undefined ? that.state.token : that.state.stateInfo.token);

    // Update file information
    let tempGrantPermissionForm = that.state.grantPermissionForm;
    tempGrantPermissionForm.file.fileName = rowData.values.fileName;
    tempGrantPermissionForm.file.id = rowData.values.id;
    that.setState({ grantPermissionForm: tempGrantPermissionForm });

    // Update session timeout
    that.updateStateExpiration();

    // Attempt to get the list of users.
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/user/all`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "GET"
      },
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      }
      else {
        throw response;
      }
    })
    .then(function(response) {
      // Parse the list of users into a usable structure which can be displayed.
      var tempUserList = [];
      var idx;
      for (idx = 0; idx < response.length; idx++) {
        let currentUser = response[idx];
        // If the current user is not a Customer do not include them in the list of users.
        if (currentUser.role !== "Customer") {
          continue;
        }
        // Format the user for display as: firstname lastname (company)
        tempUserList.push({ label: currentUser.firstName + " " + currentUser.lastName, value: currentUser.email, company: currentUser.company });
      }
      // Once all of the users have been iterated through, update the current state's user tree structure.
      that.setState({ userList: tempUserList });
      // Then open the file permission pop-up.
      that.openPermissionPopup();
    })
    .catch(function(err) {
      console.log(err);
      alert('User List Update Failed');
    });
  }

  // Method which calls the "user/info" endpoint to get the current user's info such as company, first and last name, email and role.
  getUserInfo(email, token) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;

    // Attempt to get the list of files that the current user has permission to download.
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/user/info`, {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
      "body": JSON.stringify({
        "email": email,
      }),
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      }
      else {
        throw response;
      }
    })
    .then(function(response) {
      // If the current session is not timed out
      var state = store.get('state');
      var authToken = store.get('authenticationTokenExpiration');
      if (authToken !== undefined) {
        var tempStateInfo = {
          email: response.email,
          firstName: response.firstName,
          lastName: response.lastName,
          company: response.company,
          role: response.role,
          token: state.authenticationToken,
        };
        that.setState({ stateInfo: tempStateInfo });
        var expirationTime = store.get('authenticationTokenExpiration').expiration;
        store.set('stateInfo', tempStateInfo, expirationTime);
      }
    })
    .catch(function(err) {
      console.log(err);
      alert('User Info Failed To Load');
    });
  }

  updateStateExpiration() {
    var state = store.get('state');
    if (state !== undefined) {
      var updatedExpiration = new Date().getTime() + ONE_HOUR_MS;
      var tempStateInfo = store.get('stateInfo');
      if (tempStateInfo === undefined) {
        this.getUserInfo(state.email, state.authenticationToken);
        tempStateInfo = store.get('stateInfo');
      }
      if (updatedExpiration <= store.get('authenticationTokenExpiration').expiration) {
        store.set('state', state, updatedExpiration);
        store.set('loggedIn', true, updatedExpiration);
        store.set('stateInfo', tempStateInfo, updatedExpiration);
      }
      else {
        store.set('state', state, store.get('authenticationTokenExpiration').expiration);
        store.set('loggedIn', true, store.get('authenticationTokenExpiration').expiration);
        store.set('stateInfo', tempStateInfo, store.get('authenticationTokenExpiration').expiration);
      }
    }
  }

  closeUploadFilePopup() {
    this.setState({ showUploadFilePopup: false });
    var tempFormValue = {product: '', productVersion: '', fileType: '', company: '', releaseDate: this.state.date};
    this.setState({ newFile: null, formValue: tempFormValue });
  }

  closePermissionPopup() {
    // Reset the data in the grant permission form.
    var tempGrantPermissionForm = { user: { email: '' }, file: { id: this.state.fileInfo.fileId }, endDate: this.state.endOfDate };
    this.setState({ showSharePopup: false, grantPermissionForm: tempGrantPermissionForm });
  }

  resetRows() {
    this.setState({ popupRows: 0 });
  }

  resetPermissionRows() {
    this.setState({ permissionPopupRows: 0 });
  }

  openUploadFilePopup(event) {
    this.updateCompanyList(store.get('stateInfo').token);

    this.setState({ showUploadFilePopup: true });
    setTimeout(() => {
      this.setState({
        popupRows: 80
      });
    }, 2000);
    if (this.state.productList === undefined) {
      this.getProductList();
    }
  }

  openPermissionPopup(event) {
    this.setState({ showSharePopup: true });
    setTimeout(() => {
      this.setState({
        permissionPopupRows: 80
      });
    }, 2000);
  }

  handleFileUpload() {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;
    // Update the release date in the file properties to be at the beginning of the selected day.
    var tempFormValue = JSON.parse(JSON.stringify(this.state.formValue));
    var tempDate = new Date(tempFormValue.releaseDate);
    tempDate.setHours(0,0,0,0);
    tempFormValue.releaseDate = tempDate;
    // Create a Blob object containing the file properties to be sent to the backend.
    var formBlob = new Blob([JSON.stringify(tempFormValue)], {type: "application/json"});

    var formdata = new FormData();
    formdata.append("file", this.state.newFile);
    formdata.append("file-properties", formBlob);

    // Attempt to upload a new file
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/file/new`, {
      "method": "POST",
      "headers": {
        "Content": "application/json",
        "Authorization": "Bearer " + this.state.token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
      "body": formdata,
    })
    .then(function(response) {
      if (response.ok) {
        alert("File Successfully Uploaded");
        // Close the pop-up window.
        that.closeUploadFilePopup();
        // Update the file list being displayed.
        that.handleFilesList(that.state.email, that.state.token);
      }
      else {
        throw response;
      }
    })
    .catch(function(err) {
      console.log(err);
      alert('File Upload Failed');
    });
  }
  
  handleManageUsers() {
    this.props.history.push('/ManageUsers');
  }

  handleFilesPage() {
    this.props.history.push('/Files');
  }

  updateCompanyList(token) {
    const that = this;
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/company/all`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "GET"
      },
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      }
      else {
        throw response;
      }
    })
    .then(function(response) {
      var tempCompanyList = [];
      var idx;
      for (idx = 0; idx < response.length; idx++) {
        let company = response[idx];
        tempCompanyList.push({ label: company.name, value: company.name });
      }
      that.setState({ companyList: tempCompanyList});

    })
    .catch(function(err) {
      console.log(err);
      alert('Company List Update Failed');
    });
  }

  componentDidMount() {
    // If user has timed out, sign them out.
    if (store.get('authenticationTokenExpiration') === undefined) {
      this.handleLogout();
    } else {
      if (store.get('stateInfo') === undefined) { // If the user has just logged in and the stateInfo has not yet been set, set the stateInfo.
        this.getUserInfo(store.get('state').email, store.get('state').authenticationToken);
      } else {
        this.setState({ stateInfo: store.get('stateInfo') });
      }
      this.updateStateExpiration();
    }
    try {
      if (store.get('stateInfo') !== undefined) {
        this.setState({ email: store.get('stateInfo').email, token: store.get('stateInfo').token, role: store.get('stateInfo').role });
        this.handleFilesList(store.get('stateInfo').email, store.get('stateInfo').token);
      } else {
        this.setState({ email: store.get('state').email, token: store.get('state').authenticationToken});
        this.handleFilesList(store.get('state').email, store.get('state').authenticationToken);
      }
    } catch (err) {
        console.log(err);
    }
  }

  // Method which calls the "file/all" endpoint to get the list of files that the current user has permission to download.
  handleFilesList(email, token) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;

    // Attempt to get the list of files that the current user has permission to download.
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/file/all?userEmail=${email}`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "GET"
      }
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      }
      else {
        throw response;
      }
    })
    .then(function(response) {
      var tempFilesList = [];
      var tempFileProductFilter = [];
      var tempFileProductFilterList = [];
      var tempFileTypeFilter = [];
      var tempFileTypeFilterList = [];

      var i;
      for (i = 0; i < response.length; i++) {
        let file = response[i];
        let tempFileTemplate = JSON.parse(JSON.stringify(that.state.fileTemplate));
        tempFileTemplate.id = file.id;
        tempFileTemplate.fileName = file.fileName;
        tempFileTemplate.releaseDate = file.releaseDate.split("T")[0];
        tempFileTemplate.fileType = file.fileType;
        tempFileTemplate.product = file.product;
        tempFileTemplate.productVersion = file.productVersion;
        if (file.company && file.company.name) {
          tempFileTemplate.company = file.company.name;
        } else {
          tempFileTemplate.company = "-";
        }
        if (file.endDate) {
          tempFileTemplate.endDate = file.endDate.split("T")[0];
        } else {
          tempFileTemplate.endDate = "-";
        }
        tempFilesList.push(tempFileTemplate);
        let existingProduct = tempFileProductFilter.indexOf(file.product.id.toString());
        if (existingProduct === -1) {
          tempFileProductFilter.push(file.product.id.toString());
          // If there's an HTML value, use that for the label.
          // If there's no HTML but there is a (text) label value, use that.
          // If there's no HTML or text label, default to just the name.
          let label = file.product.name;
          if (file.product.html) {
            label = <span dangerouslySetInnerHTML={{ __html: file.product.html }}></span>;
          } else if (file.product.label) {
            label = file.product.label;
          }
          let children = [];
          children.push({ value: file.productVersion, label: file.productVersion });
          tempFileProductFilterList.push({ value: file.product.id.toString(), label: label, children: children });
        } else {
          // Product is already in the list. Check if the product version is in the children list
          if (!tempFileProductFilterList[existingProduct].children.some(version => version.value === file.productVersion)) {
            // Not in the list, add it
            tempFileProductFilterList[existingProduct].children.push({ value: file.productVersion, label: file.productVersion });
          } // else already in the list
        }

        if (tempFileTypeFilter.indexOf(file.fileType) === -1) {
          tempFileTypeFilter.push(file.fileType);
          tempFileTypeFilterList.push({ value: file.fileType, label: file.fileType });
        }
      }
      // Once all of the downloadable files have been iterated through,
      // push the new list to update the state fileList
      that.setState({
        filesList: tempFilesList,
        fileProductFilter: tempFileProductFilter,
        fileProductFilterList: tempFileProductFilterList,
        fileTypeFilter: tempFileTypeFilter,
        fileTypeFilterList: tempFileTypeFilterList,
      });
    })
    .catch(function(err) {
      console.log(err);
      alert('File List Update Failed');
    });
  }

  onNewFileChangeHandler(event) {
    this.setState({
      newFile: event.target.files[0],
      loaded: 0,
    });
    // If the newFile is not null and the form fields are all populated, enable the submit button.
    if (this.state.newFile !== null && this.state.formValue.product !== '' && this.state.formValue.productVersion !== '' && this.state.formValue.fileType !== '' && this.state.formValue.releaseDate !== null) {
      this.setState({ submitButtonStatus: false });
    } else if (this.state.submitButtonStatus === false) {
      this.setState({ submitButtonStatus: true });
    }
  }

  onFilePropertiesChange(formValue) {
    this.setState({ formValue });
    // If the newFile is not null and the form fields are all populated, enable the submit button.
    if (this.state.newFile !== null && formValue.product !== '' && formValue.productVersion !== '' && formValue.fileType !== '' && formValue.releaseDate !== null) {
      this.setState({ submitButtonStatus: false });
    } else if (this.state.submitButtonStatus === false) {
      this.setState({ submitButtonStatus: true });
    }
  }

  // Function to update states when text in text boxes is changed
  handleChange(changeObject) {
    this.setState(changeObject);
  }

  handleLogout() {
    store.remove('loggedIn');
    store.remove('state');
    store.remove('authenticationTokenExpiration');
    store.remove('stateInfo');
    this.props.history.push('/Login');
  }

  handleFileDownload(rowData) {
    // Attempt to download a new file
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/file/download`, {
      "method": "POST",
      "headers": {
        "Content": "text/plain",
        "Content-Type": "text/plain",
        "Authorization": "Bearer " + this.state.token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
      "body": rowData.values.id,
    })
    .then(function(response) {
      if (response.ok) {
        return response.text();
      }
      else {
        throw response;
      }
    })
    .then(function(url) {
      let link = document.createElement('a');
      document.body.appendChild(link);
      link.href = url;
      link.target="_blank";
      link.click();
      document.body.removeChild(link);
    })
    .catch(function(err) {
      console.error(err);
      alert('File Download Failed');
    });
  }

  getIndividualPermissionInfo(email, fileId) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;

    return fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/file/permit/info?userEmail=${email}&fileIdStr=${fileId}`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + this.state.token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "GET"
      },
    })
    .then(function(response) {
      if (response.ok) {
        if (response.status === 200) {
          return response.json();
        } else {
          // If the user doesn't have specific permission this 
          return JSON.parse("{}");
        }
      }
      else {
        throw response;
      }
    })
    .then(function(response) {
      var tempFileInfo = that.state.fileInfo;
      if (response.endDate) {
        tempFileInfo.endDate = response.endDate.split("T")[0];
      } else {
        tempFileInfo.endDate = '';
      }
      that.setState({ fileInfo: tempFileInfo });
    })
    .catch(function(err) {
      console.error(err);
      alert('File permission lookup failed');
    });
  }

  getProductList() {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;
    return fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/product/all`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + this.state.token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "GET"
      },
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      }
      else {
        return JSON.parse("[]");
      }
    })
    .then(function(response) {
      var tempProductList = [];
      response.forEach(function(item, index) {
        if (item.name) {
          // If there's an HTML value, use that for the label.
          // If there's no HTML but there is a (text) label value, use that.
          // If there's no HTML or text label, default to just the name.
          let label = item.name;
          if (item.html) {
            label = <span dangerouslySetInnerHTML={{ __html: item.html }}></span>;
          } else if (item.label) {
            label = item.label;
          }
          var product = { value: item.id, label: label, role: 'Master' };
          tempProductList.push(product);
        }
      });
      that.setState({ productList: tempProductList });
    })
    .catch(function(err) {
      console.error(err);
      alert('Product lookup failed');
    });
  }

  render() {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;

    const isCustomer = (this.state.stateInfo.role === 'Customer');

    const fileColumns = [
      {
        Header: "Product",
        accessor: "product.name",
      },
      {
        Header: "File Name",
        accessor: "fileName",
        style: {
          textAlign: 'left'
        },
      },
      {
        Header: "Product Version",
        accessor: "productVersion",
      },
      {
        Header: "File Type",
        accessor: "fileType",
      },
      {
        Header: "Release Date",
        accessor: "releaseDate",
      },
      {
        Header: isCustomer?"End Date":"Customer",
        accessor: isCustomer?"endDate":"company",
      },
      {
        Header: "Download",
        accessor: "id",
        Cell: ({ cell: { row } }) =>
        <button type="button" onClick={() => this.handleFileDownload(row)}>
          <img src={require('../download.png')} alt="Download"/>
        </button>,
      },
    ];
    if (!isCustomer) {
      // Admin or maintainer
      fileColumns.push(
        {
          Header: "Share",
          Cell: ({ cell: { row } }) =>
          <button type="button" onClick={() => this.handleOpenPermissionPopup(row)}>
            <img src={require('../share.png')} alt="Share"/>
          </button>,
        },
        {
          Header: "Delete",
          Cell: ({ cell: { row } }) =>
          <button type="button" onClick={() => this.handleDeleteFile(row)}>
            <img src={require('../delete.png')} alt="Delete"/>
          </button>,
        }
      );
    }

    if (store.get('loggedIn') !== true) {    // If the user is not logged in, redirect them to the login page
      return <Redirect to="/Login" />;
    }

    const { formValue } = this.state;

    let productList = [];
    // Start with all products available to the user
    if (this.state.productList !== undefined) {
      productList = this.state.productList;
    }

    // Full list of filenames for adding to the search field
    let fileNameList = [];
    this.state.filesList.forEach(function(v, i) {
      if (fileNameList.indexOf(v.fileName) === -1) {
        fileNameList.push(v.fileName);
      }
    });

    // Filter out by search criteria
    let filteredFileslistData = this.state.filesList.filter((v, i) => {
      if (this.state.fileSearch && v.fileName.toLowerCase().indexOf(this.state.fileSearch) === -1) {
        return false;
      }

      if (this.state.fileProductFilter) {
        let productId = v.product.id.toString();
        let productVersion = productId + "::" + v.productVersion;
        if (this.state.fileProductFilter.indexOf(productId) === -1
          && this.state.fileProductFilter.indexOf(productVersion) === -1) {
          // The product isn't selected, and the product::version isn't selected
          return false;
        }
      }
      if (this.state.fileTypeFilter && this.state.fileTypeFilter.indexOf(v.fileType) === -1) {
        return false;
      }
      if (this.state.fileStartDateFilter && this.state.fileStartDateFilter > v.releaseDate) {
        return false;
      }
      if (this.state.fileEndDateFilter && this.state.fileEndDateFilter < v.releaseDate) {
        return false;
      }
      return true;
    });

    return(
      <div className="Files-Container">
        <Modal show={this.state.showSharePopup} onHide={this.closePermissionPopup} onExited={this.resetPermissionRows}>
          <Modal.Header>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ControlLabel className="Date-Picker-Label"><div className="Title">File: </div>{that.state.grantPermissionForm.file.fileName}</ControlLabel>
            <ControlLabel className='Form-Input-Label Title'>User: </ControlLabel>
            <InputPicker className="Form-Input" name="customer" data={this.state.userList} groupBy="company" autoComplete="off"
              sort={isGroup => {
                if (isGroup) {
                  return (a, b) => {
                    return (a.groupTitle.toUpperCase() > b.groupTitle.toUpperCase())?1:-1;
                  }
                } else {
                  return (a, b) => {
                    return (a.value.toUpperCase() > b.value.toUpperCase())?1:-1;
                  }
                }
              }}
              onChange={ (value, event) => {
                if (value) {
                  // Enable the grant permission button and store the selected user's information somewhere so that when the submit button is pressed, the fetch call will know what user to grant permission to.
                  let tempFormData = that.state.grantPermissionForm;
                  tempFormData.user.email = value;
                  that.setState({ grantPermissionForm: tempFormData, submitPermissionButtonStatus: false });
                } else {
                  // Disable the grant permission button
                  let tempFormData = that.state.grantPermissionForm;
                  tempFormData.user.email = '';
                  that.setState({ grantPermissionForm: tempFormData, submitPermissionButtonStatus: true });
                }
              }}
            />

            <ControlLabel className="Date-Picker-Label Title">Expires: </ControlLabel>
            <div className="Date-Picker">
              <DatePicker oneTap className="Form-Input" placement="autoVerticalStart" value={this.state.grantPermissionForm.endDate} onChange={((e) => this.updatePermissionEndDate(e))}/>
            </div>
            <ButtonToolbar>
              <Button onClick={(e) => this.submitNewFilePermission(this.state.grantPermissionForm)} disabled={this.state.submitPermissionButtonStatus} appearance='primary'>
                Share
              </Button>
              <Button onClick={this.closePermissionPopup}>
                Cancel
              </Button>
            </ButtonToolbar>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showUploadFilePopup} onHide={this.closeUploadFilePopup} onExited={this.resetRows}>
          <Modal.Header>
            <Modal.Title>Upload New File</Modal.Title>
          </Modal.Header>
          <Modal.Body id="FilesPage">
            <ControlLabel className="Form-Input-Label">Select File</ControlLabel>
            <input type="file" name="file" className='File-Input' onChange={this.onNewFileChangeHandler}/>
            <Form 
              ref={ref => (this.form = ref)}
              onChange={formValue => {
                this.onFilePropertiesChange(formValue);
              }}
              onCheck={formError => {
                this.setState({ formError });
              }}
              formValue={formValue}
              model={this.state.model}
              >
              <FormGroup>
                <ControlLabel className="Form-Input-Label">Product</ControlLabel>
                <FormControl className="Form-Input" name="product" accepter={InputPicker} data={productList}/>
              </FormGroup>
              <FormGroup>
                <ControlLabel className="Form-Input-Label">Product Version</ControlLabel>
                <FormControl className="Form-Input" name="productVersion"/>
              </FormGroup>
              <FormGroup>
                <ControlLabel className="Form-Input-Label">File Type</ControlLabel>
                <FormControl className="Form-Input" name="fileType" accepter={InputPicker} data={[{"label": "Software", "value": "Software", "role": "Master"}, {"label": "Documentation", "value": "Documentation", "role": "Master"}, {"label": "License", "value": "License", "role": "Master"}]}/>
              </FormGroup>
              <FormGroup>
                <ControlLabel>Customer (optional)</ControlLabel>
                <FormControl className="Form-Input" name="company" accepter={InputPicker} data={this.state.companyList} creatable autoComplete="off"/>
              </FormGroup>
              <FormGroup>
                <ControlLabel className="Form-Input-Label">Release Date</ControlLabel>
                <FormControl className="Form-Input" name="releaseDate" accepter={DatePicker} oneTap placement="autoVerticalStart"/>
              </FormGroup>
            </Form>
            <ButtonToolbar>
              <Button onClick={this.handleFileUpload} disabled={this.state.submitButtonStatus} appearance='primary'>
                Submit
              </Button>
              <Button onClick={this.closeUploadFilePopup}>
                Cancel
              </Button>
            </ButtonToolbar>
          </Modal.Body>
        </Modal>

        <header className="Files-Header">
          <div className='nav-wrapper'>
            <NavigationBar
              displayName={this.state.stateInfo.email}
              onLogout={this.handleLogout}
              onManageUsers={this.handleManageUsers}
              onFilesPage={this.handleFilesPage}
              onUploadFile={this.openUploadFilePopup}
              userRole={this.state.stateInfo.role}
            />
          </div>
        </header>

        <aside className="Files-Sidebar">
          <AutoComplete
            data={fileNameList}
            placeholder="Search"
            onChange={ (data) => {that.setState({ fileSearch: data.toLowerCase() });} }
            autoComplete="off"
            className="SearchBar"
          />
          <label className="FilterLabel">Filter</label>
          <CheckTree
            title={"Products"}
            items={this.state.fileProductFilterList}
            onChange={function(value) {
              // Uncheck everything means select everything
              if (!value.length) {
                value = Array.from(that.state.fileProductFilterList, product => product.value);
              }
              that.setState({ fileProductFilter: value });
            }}
          />
          <CheckTree
            title={"File Type"}
            items={this.state.fileTypeFilterList}
            onChange={function(value) {
              // Uncheck everything means select everything
              if (!value.length) {
                value = Array.from(that.state.fileTypeFilterList, fileType => fileType.value);
              }
              that.setState({ fileTypeFilter: value });
            }}
          />

          <Expandable title="Date Range" node={
            <div className="DateRangeFilter">
              <label>From:</label>
              <DatePicker format="YYYY-MM-DD" oneTap onChange={function(value) {
                if (value) {
                  value = value.toISOString().split('T')[0];
                  if (that.state.fileEndDateFilter && that.state.fileEndDateFilter < value) {
                    value = null;
                    alert("Start date must be before end date");
                    return;
                  }
                }
                that.setState({ fileStartDateFilter: value });
              }} />
              <label>To:</label>
              <DatePicker format="YYYY-MM-DD" oneTap onChange={function(value) {
                if (value) {
                  value = value.toISOString().split('T')[0];
                  if (that.state.fileStartDateFilter && that.state.fileStartDateFilter > value) {
                    value = null;
                    alert("Start date must be before end date");
                    return;
                  }
                }
                that.setState({ fileEndDateFilter: value });
              }} />
            </div>
          } />
        </aside>

        <main className="Files-List">
          <div className="MainView">
            <span className="MyFilesLabel">
              {isCustomer?"My":"All"} <span className="iS5Orange">Files</span>
            </span>
            <Table columns={fileColumns} data={filteredFileslistData} />
          </div>
        </main>

        <PortalFooter/>
      </div>

    );
  }
}
export default withRouter(FilesPage);
