import React from 'react';

export function doExpand(node) {
  if (node.parentNode.className === "PortalExpandable") {
    node.parentNode.className = "PortalExpandable PortalExpandableOpen";
  } else {
    node.parentNode.className = "PortalExpandable";
  }
}

function Expandable(props) {
  const {title, subtitle, node = <span/> } = props;
  if (title) {
    return(
      <div className="PortalExpandable">
        <div className="PortalExpandableTitle flex-row" onClick={ (event) => {doExpand(event.currentTarget);} }>
          <div role="button" className="PortalExpandableButtonWrapper">
            <span className="PortalExpandableButton">
            </span>
          </div>
          <div className="PortalExpandableLabel">
            {title}
          </div>
        </div>
        <div className="PortalExpandableNode">
          {node}
        </div>
      </div>
    );
  } else {
    return(
      <div className="PortalExpandable">
        <div className="PortalExpandableTitle flex-row">
          <div role="button" className="PortalExpandableButtonWrapper" onClick={ (event) => {doExpand(event.currentTarget.parentNode);} }>
            <span className="PortalExpandableButton">
            </span>
          </div>
          <div className="PortalExpandableSubLabel">
            {subtitle}
          </div>
        </div>
        <div className="PortalExpandableNode">
          {node}
        </div>
      </div>
    );
  }
}

export default Expandable;