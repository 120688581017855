import React from 'react';
import { Button, } from 'rsuite';

// Function to determine whether or not the admin functionalitites should be available to the current user or not. 
export default function CreateUserButton(props) {
  if (props.userRole === 'Maintainer' || props.userRole === 'Admin') {
    return (
      <Button onClick={props.open} appearance="link">Create New User</Button>
    );
  } else {
    return null;
  }
}