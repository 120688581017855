import React from 'react';
import '../styles/CustomerPortal.css';
import { Form, FormGroup, FormControl, ControlLabel, Schema, Button, ButtonToolbar, InputPicker, } from 'rsuite';

export default class RegisterUserPopup extends React.Component {
  // Constructor which receives our props
  constructor(props) {
    super(props);
    const { StringType, } = Schema.Types;
    const model = Schema.Model({
      company: StringType().isRequired('This field is required.'),
      email: StringType()
        .isEmail('Please enter a valid email address.')
        .isRequired('This field is required.'),
      role: StringType().isRequired('This field is required.')
    });
    // Next we set our state
    this.state = {
      formValue: {
        company: '',
        email: '',
        role: '',
      },
      formError: {},
      model: model,
      value: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
  }

  handleSubmit() {
    console.log(this.state.formValue);
    // Update the form value and form error value of the ManageUsersPage
    this.props.handleChange({ registerUserFormValue: this.state.formValue, registerUserFormError: this.state.formError });
    // Send the registration call to the backend
    this.props.handleRegisterNewUser(this.state.formValue);
  }

  handleCompanyChange(value) {
    this.setState({value});
  }

  render() {
    const { formValue } = this.state;
    const { value } = this.state;

    return (
      <div>
        <Form 
          ref={ref => (this.form = ref)}
          onChange={formValue => {
            this.setState({ formValue });
          }}
          onCheck={formError => {
            this.setState({ formError });
          }}
          formValue={formValue}
          model={this.state.model}
          autoComplete="off"
          >
          <FormGroup>
            <ControlLabel>Company</ControlLabel>
            <FormControl className="Form-Input" name="company" accepter={InputPicker} value={value} data={this.props.companyList} creatable onChange={this.handleCompanyChange} autoComplete="off"/>
          </FormGroup>

          <FormGroup>
            <ControlLabel>Email</ControlLabel>
            <FormControl className="Form-Input" name="email"/>
          </FormGroup>

          <FormGroup>
            <ControlLabel>Role</ControlLabel>
            <FormControl className="Form-Input" name="role" accepter={InputPicker} data={[{"label": "Customer", "value": "Customer", "role": "Master"}, {"label": "Internal Customer", "value": "InternalCustomer", "role": "Master"}, {"label": "Maintainer", "value": "Maintainer", "role": "Master"}, {"label": "Admin", "value": "Admin", "role": "Master"}]}/>
          </FormGroup>

          <ButtonToolbar>
            <Button appearance="primary" type="submit" onClick={this.handleSubmit}>
              Submit
            </Button>
            <Button appearance="subtle" onClick={this.props.handleClose}>
              Cancel
            </Button>
          </ButtonToolbar>
        </Form>
      </div>
    );
  }
}
