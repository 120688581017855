import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import Routes from './components/Routes.js';
import { LastLocationProvider } from 'react-router-last-location';

function App() {
  return (
    <Router>
      <LastLocationProvider>
        <Routes />
      </LastLocationProvider>
    </Router>
  );
}
// Read through this tutorial to learn about how to handle where users can navigate on the website and what they are allowed to see/access
// https://scotch.io/courses/using-react-router-4/authentication-with-redirect

export default App;
