import React from 'react';
import '../styles/CustomerPortal.css';
import Logo from './Logo.js';
import { withRouter} from 'react-router-dom';

class ForgotPasswordPage extends React.Component {
  // Constructor which receives our props
  constructor(props) {
    super(props);
    // Next we set our state
    this.state = {
      email: '',
      password: '',
      passwordConfirmation: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleResetRequest = this.handleResetRequest.bind(this);
    this.handlePasswordReset = this.handlePasswordReset.bind(this);
  }

  handleResetRequest(event) {
    if (!this.state.email) {
      alert("Please enter an email address to request a password reset");
      event.preventDefault();
      return;
    }

    // Attempt to authenticate the entered user
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/user/resetPassword`, {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
      "body": this.state.email,
    })
    .then(function(response) {
      if (response.ok) {
        alert("Password request has been submitted. Please wait for email.");
      } else {
        throw response;
      }
    })
    .catch(function(err) {
      console.log(err);
      alert("Password reset failed");
    });
   
    event.preventDefault();

  }

  handlePasswordReset(event, token) {
    // Password must be at least 8 Characters, 1 upper-case, 1 lower-case, 1 digit, 1 special character
    const regex = /^.*(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]).*$/;
    // This comment is to help text editors by closing the open quote in the previous line: `

    if (!this.state.password || !regex.test(this.state.password)) {
      alert("Please enter a valid password");
      event.preventDefault();
      return;
    }
    if (!this.state.passwordConfirmation || this.state.passwordConfirmation !== this.state.password) {
      alert("Password and confirmation do not match");
      event.preventDefault();
      return;
    }

    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;
    // Attempt to authenticate the entered user
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/user/changePassword?token=` + token, {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
      "body": this.state.password,
    })
    .then(function(response) {
      if (response.ok) {
        alert("Password change successful. Please try logging in again.");
        that.props.history.push('/Login');
      } else {
        throw response;
      }
    })
    .catch(function(err) {
      console.log(err);
      alert("Password reset failed");
    });

    event.preventDefault();
  }

  // Function to update states when text in text boxes is changed
  handleChange(changeObject) {
    this.setState(changeObject);
  }

  // Render function
  render() {
    const query = new URLSearchParams(this.props.location.search);
    let token = query.get("token");
    if (token) {
      return(
        <div className="row h-100">
          <div id="LoginPage" className="col mx-auto my-auto">
            <Logo type='banner'/>
            <div className="row justify-content-center Login-Page">
              <div className="col-md-8">
                <div className="Login-Title">
                  Enter a new password
                </div>
                <form className="d-flex flex-column" onSubmit={ (e) => this.handlePasswordReset(e, token) }>
                  <div className="form-group row">
                    <label htmlFor="password" className="col-sm-3 text-left">
                      Password:
                    </label>
                    <input 
                      name="password"
                      id="password"
                      type="password"
                      className="form-control Login-Fields col-sm-8"
                      onChange={(e) => this.handleChange({ password: e.target.value })} 
                      required
                      />
                  </div>
                  <div className="form-group row">
                    <label htmlFor="passwordConfirmation" className="col-sm-3 text-left">
                      Confirm Password:
                    </label>
                    <input 
                      name="passwordConfirmation"
                      id="passwordConfirmation"
                      type="password"
                      className="form-control Login-Fields col-sm-8"
                      onChange={(e) => this.handleChange({ passwordConfirmation: e.target.value })} 
                      required
                      />
                  </div>
                  <div className="p-2">
                    <button className="Sign-In-Button" type='submit'>
                      Reset Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    } else { // No token, ask for email
      return(
        <div className="row h-100">
          <div id="LoginPage" className="col mx-auto my-auto">
            <Logo type='banner'/>
            <div className="row justify-content-center Login-Page">
              <div className="col-md-8">
                <div className="Login-Title">
                  Password reset
                </div>
                <form className="d-flex flex-column" onSubmit={ this.handleResetRequest }>
                  <div className="form-group row">
                    <label htmlFor="email" className="col-sm-2 text-left">
                      Email:
                    </label>
                    <input 
                      name="email"
                      id="email"
                      type="text"
                      className="form-control Login-Fields col-sm-9"
                      onChange={(e) => this.handleChange({ email: e.target.value })} 
                      required
                      />
                  </div>
                  <div className="p-2">
                    <button className="Sign-In-Button" type='submit'>
                      Reset Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(ForgotPasswordPage);

