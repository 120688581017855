import React from 'react';
import { Dropdown, Icon, } from 'rsuite';

function menuItem(key, onSelect, label) {
  if (onSelect) {
    return <Dropdown.Item eventKey={key} onSelect={onSelect}>{label}</Dropdown.Item>;
  } else {
    return <Dropdown.Item eventKey={key} disabled>{label}</Dropdown.Item>;
  }
}

// Function to determine whether or not the admin functionalitites should be available to the current user or not. 
export default function NavigationBarAdminFunctions(props) {
  const { onManageUsers, onUploadFile } = props;
  if (props.userRole === 'Maintainer' || props.userRole === 'Admin') {
    return (
        <Dropdown eventKey="3" title="Admin Functions" icon={<Icon icon="gear-circle" />}>
        {menuItem("3-1", onManageUsers, "Manage Users")}
        {menuItem("3-2", onUploadFile, "Upload New File")}
        </Dropdown>
    );
  } else {
    return null;
  }
}
