import React from 'react';

function PortalFooter() {
  return (
    <footer className="Portal-Footer">
      <div className="Portal-Footer-Text">Technical Support: 1-844-475-TECH</div>
      <div className="Portal-Footer-Separator">|</div>
      <div className="Portal-Footer-Text">Technical Support: support@is5com.com</div>
      <div className="Portal-Footer-Separator">|</div>
      <div className="Portal-Footer-Text">Sales: orders@is5com.com</div>
      <div className="Portal-Footer-Separator">|</div>
      <div className="Portal-Footer-Text">General: info@is5com.com</div>
    </footer>
  );
}
export default PortalFooter;