import React from 'react';
import Expandable, {doExpand} from "./Expandable.js"

function findCheckedValues(node) {
  let checkedValues = [];
  if (node.type && node.type === 'checkbox') {
    if (node.checked) {
      checkedValues.push(node.value);
    }
  }
  node.childNodes.forEach( (item, index) => {
    checkedValues = checkedValues.concat(findCheckedValues(item));
  });
  return checkedValues;
}

function handleCheckChange(node) {
  let checkedList = [];
  node.childNodes.forEach( (item, index) => {
    checkedList = checkedList.concat(findCheckedValues(item));
  });
  return checkedList;
}

function CheckItem(item, onChange, depth, prefix) {
  let value = item.value;
  if (prefix) {
    value = prefix + value;
  }
  if (item.children) {
    let subPrefix = value + "::";
    let childrenNodes = item.children.map( item => (
      <div className="PortalCheckTreeItem" key={item.value}>
        { CheckItem(item, onChange, depth + 1, subPrefix ) }
      </div>
    ));
    return Expandable( {subtitle: <>
          <input
            type="checkbox"
            value={value}
            className="PortalCheckTreeItemCheckBox"
            onChange={ (event) => {
              let node = event.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode;
              for (let i = 0; i < depth; ++i) {
                // Skip two nodes per level (two nested div tags)
                node = node.parentNode.parentNode;
              }
              let checkedList = handleCheckChange(node);
              onChange(checkedList);
            }}
          />
          <span className="PortalCheckTreeItemLabel" onClick={ (event) => {doExpand(event.currentTarget.parentNode.parentNode);} }>{item.label}</span>
      </>, node: childrenNodes} );
  } else {
    return <>
      <input
        type="checkbox"
        value={value}
        className="PortalCheckTreeItemCheckBox"
        onChange={ (event) => {
          let node = event.currentTarget.parentNode.parentNode.parentNode;
          for (let i = 0; i < depth; ++i) {
            // Skip two nodes per level (two nested div tags)
            node = node.parentNode.parentNode;
          }
          let checkedList = handleCheckChange(node);
          onChange(checkedList);
        }}
      />
      <span className="PortalCheckTreeItemLabel">
        {item.label}
      </span>
    </>
  }
}

function CheckTree(props) {
  const {title = "Filter", items = [], onChange = () => {}} = props;
  let checkItemNodes = items.map( item => (
    <div className="PortalCheckTreeItem" key={item.value}>
      { CheckItem(item, onChange, 0) }
    </div>
  ));

  return(Expandable( { title, node: checkItemNodes }));
}

export default CheckTree;