import React from 'react';
import { useTable, usePagination } from "react-table";

function Table( props ) {
  // Memoize the incoming data so changes to sort or filter will be faster.
  // Hooks back on itself so that if the data changes it automatically refreshes the memoization
  // (not that the data is expected to change in our use case, this is just good practice)
  const columns = React.useMemo( () => props.columns, [props.columns] );
  const data = React.useMemo( () => props.data, [props.data] );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable( {
      columns,
      data,
      initialState: { pageSize: props.pageSize?props.pageSize:10 },
    },
    usePagination
  );

  // Display a "Previous" button, or don't
  var previousButton = canPreviousPage?<div className="TablePagePrevious">
    <button onClick={() => previousPage()}>
      <img src={require('../previous.png')} alt="Previous Page" className="TablePaginationButton"/>
      {' '}
      <span className="TablePaginationButton">Previous Page</span>
    </button>
  </div>
  :<div className="TablePagePrevious"/>;

  // Display a "Next" button, or don't
  var nextButton = canNextPage?<div className="TablePageNext">
    <button onClick={() => nextPage()}>
      <span className="TablePaginationButton">Next Page</span>
      {' '}
      <img src={require('../next.png')} alt="Next Page" className="TablePaginationButton"/>
    </button>
  </div>
  :<div className="TablePageNext"/>;

  return (
  <>
    <div className="Table" {...getTableProps()}>
      <div className="TableHeading">
      {headerGroups.map(headerGroup => (
        <div className="TableRow" {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <div className="TableCell" {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                ])}>{column.render("Header")}</div>
          ))}
        </div>
      ))}
      </div>
      <div className="TableBody" {...getTableBodyProps()}>
      {page.map((row, i) => {
        prepareRow(row);
        return (
          <div className="TableRow" {...row.getRowProps()}>
            {row.cells.map(cell => {
              return <div className="TableCell" {...cell.getCellProps([
                  {
                    className: cell.column.className,
                    style: cell.column.style,
                  },
                ])}>{cell.render("Cell")}</div>;
            })}
          </div>
        );
      })}
      </div>
    </div>

    <div className="TablePagination">
      {previousButton}

      <div className="TablePageCount">
        Page {pageIndex + 1}/{pageOptions.length}
      </div>

      {nextButton}
    </div>
  </>
  );
}

export default Table;