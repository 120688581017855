import React from 'react';
import { Nav, Dropdown, Icon, Navbar, Button} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import '../styles/CustomerPortal.css';
import NavigationBarAdminFunctions from '../component_functions/NavigationBarAdminFunctions.js';
import Logo from './Logo.js';

export default function NavigationBar(props) {
  const {displayName, onFilesPage, onManageUsers, onUploadFile, userRole, onLogout } = props;
  return (
    <div className='NavBar-Container' id='NavBar'>
      <Navbar appearance='default'>
        <Navbar.Header>
          <Logo/>
        </Navbar.Header>
        <Navbar.Body>
          <Nav appearance='subtle'>
            <Button className="NavBar-Button" onClick={onFilesPage}>
              <Nav.Item eventKey="2" onSelect={onFilesPage} icon={<Icon icon="dashboard" />}>
                My Files
              </Nav.Item>
            </Button>
            <NavigationBarAdminFunctions onManageUsers={onManageUsers} onUploadFile={onUploadFile} userRole={userRole}/>
          </Nav>
          <Nav pullRight>
            <Dropdown eventKey="1" title={"Welcome, " + displayName} >
              <Dropdown.Item eventKey="1-1">My Profile</Dropdown.Item>
              <Dropdown.Item eventKey="1-2" onSelect={onLogout}>Logout</Dropdown.Item>
            </Dropdown>
          </Nav>
        </Navbar.Body>
      </Navbar>
    </div>
  );
}