import React from 'react';

function Logo(props) {
  if (props.type === 'banner') {
    return (
      <div>
        <img src={require('../is5_logo.png')} alt="iS5 Logo"/>
      </div>
    );
  }

  return (
    <div>
      <img src={require('../is5_logo.png')} alt="iS5 Logo" width='245' height='40'/>
    </div>
  );
}
export default Logo;