import React from 'react';
import '../styles/CustomerPortal.css';
import store from 'store';
import Logo from './Logo.js';
import { Redirect, withRouter} from 'react-router-dom';

// Add the Expire plugin to store.js:
var expirePlugin = require('store/plugins/expire');
store.addPlugin(expirePlugin);
const ONE_HOUR_MS = 60*60*1000;
const ONE_DAY_MS = 24*60*60*1000;

class LoginPage extends React.Component {
  // Constructor which receives our props
  constructor(props) {
    super(props);
    // Next we set our state
    this.state = {
      email: '',
      password: '',
      response_token: '',
      token: '',
    }
    // To use the 'this' keyword, we need to bind it to our function
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
  }

  // Function to update states when text in text boxes is changed
  handleChange(changeObject) {
    this.setState(changeObject);
  }

  // Custom function to handle the event where the submit button is pressed
  handleSubmit(event) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;
    // Attempt to authenticate the entered user
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/authenticate`, {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
      "body": JSON.stringify({
        "email": this.state.email,
        "password": this.state.password
      })
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        throw response;
      }
    })
    .then(function(responseToken) {
      var authenticationTokenExpiration = new Date().getTime() + ONE_DAY_MS;  // 24 hours from now.
      var expiration = new Date().getTime() + ONE_HOUR_MS;  // set expiration date of login to be 1 hour from now in milliseconds.
      store.set('loggedIn', true, expiration);  //Set logged in to be true if you have succcessfully logged in.
      // Upon redirect, pass state in with the token received from the HTTP response
      store.set('state', { email: that.state.email, authenticationToken: responseToken.jwt }, expiration);
      store.set('authenticationTokenExpiration', { expiration: authenticationTokenExpiration }, authenticationTokenExpiration);
      that.props.history.push('/Files');
    })
    .catch(function(err) {
      console.log(err);
      alert('Login Failed: Invalid User Credentials');
    });
   
    event.preventDefault();
  }

  handleSignUp() {
    this.props.history.push("/Register");
  }

  // Render function
  render() {
    if (store.get('loggedIn') === true) { // If the user is already logged in, redirect them to the files page.
      return <Redirect to="/Files" />;
    }

    return (
      <div className="row h-100">
        <div id="LoginPage" className="col mx-auto my-auto">
          <Logo type='banner'/>
          <div className="row justify-content-center Login-Page">
            <div className="col-md-8">
              <div className="Login-Title">
                Please Sign In
              </div>
              <form className="d-flex flex-column" onSubmit={this.handleSubmit}>
                <div className="form-group row">
                  <label htmlFor="email" className="col-sm-2 text-left">
                    Email:
                  </label>
                  <input 
                    name="email"
                    id="email"
                    type="text"
                    className="form-control Login-Fields col-sm-9"
                    value={this.state.email}
                    onChange={(e) => 
                      this.handleChange({ email: e.target.value })} 
                    required
                    />
                </div>
                <div className="form-group row">
                  <label htmlFor="password" className="col-sm-2 text-left">
                    Password:
                  </label>
                  <input 
                    type="password"
                    name="password"
                    id="password"
                    className="form-control Login-Fields col-sm-9"
                    value={this.state.password}
                    onChange={(e) => 
                      this.handleChange({ password: e.target.value })}
                    required
                    />
                </div>
                <div className="p-2">
                  <button className="Sign-In-Button" type="submit">
                    Sign In
                  </button>
                </div>
                <label>
                  Don't have an account?
                </label>
                <div className="p-2">
                  <button className="Sign-In-Button" type='button' onClick={this.handleSignUp}>
                    Register Here
                  </button>
                </div>
              </form>
             <a href="https://is5com.com/technical-support/">Contact Support</a> | <a href="/ForgotPassword">Forgot Password</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(LoginPage);
