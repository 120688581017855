import React from 'react';
import '../styles/CustomerPortal.css';
import Logo from './Logo.js';
import { Redirect, withRouter} from 'react-router-dom';
import 'rsuite/dist/styles/rsuite-default.css';
import store from 'store';
import { Container, Header, Content, Form, FormGroup, FormControl, HelpBlock, Schema, } from 'rsuite';

// Add the Expire plugin to store.js:
var expirePlugin = require('store/plugins/expire');
store.addPlugin(expirePlugin);

const queryParams = new URLSearchParams(window.location.search);

class UserSignUpPage extends React.Component {
  // Constructor which receives our props
  constructor(props) {
    super(props);
    const { StringType, } = Schema.Types;
    var specialCharacterFormat = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    //This comment is only to close a quote character in the previous line for developer sanity: `
    // Password must be at least 8 Characters, 1 upper-case, 1 lower-case, 1 digit, 1 special character
    const model = Schema.Model({
      registrationToken: StringType().isRequired('This field is required.'),
      email: StringType()
        .isEmail('Please enter a valid email address.')
        .isRequired('This field is required.'),
      firstName: StringType(),
      lastName: StringType(),
      password: StringType().isRequired('This field is required.')
        .minLength(8, "Password must be at least eight characters long.")
        .maxLength(64, "Password must not be longer than 64 characters.")
        .containsUppercaseLetter("Password must contain at least one upper-case letter.")
        .containsLowercaseLetter("Password must contain at least one lower-case letter.")
        .containsNumber("Password must contain at least one digit.")
        .addRule((value, data) => {
            return specialCharacterFormat.test(value);
          }, 'Password must contain at least one special character: ! @ # $ % ^ & * ( ) : ; , . < > / ? _ - + ='),
      passwordConfirmation: StringType().isRequired('This field is required.')
        .addRule((value, data) => {
            if (value !== data.password) {
              return false;
            }
            return true;
          }, 'The passwords do not match.')
    });
    // Next we set our state
    this.state = {
      password: '',
      response_token: '',
      token: '',
      model: model,
      formValue: {
        registrationToken: queryParams.get('token'),
        email: queryParams.get('email'),
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirmation: '',
      },
      formError: {},
    }

    // To use the 'this' keyword, we need to bind it to our function
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  // If the user accidentally clicked the "Sign Up" button on the Sign In page, they can click the close button to bring them back to the Sign In page.
  handleClose() {
    this.props.history.push('/Login');
  }

  // Function to update states when text in text boxes is changed
  handleChange(changeObject) {
    this.setState(changeObject);
  }

  // Custom function to handle the event where the submit button is pressed
  handleSubmit(event) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;
    // Attempt to authenticate the entered user
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/registration`, {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": this.state.formValue.registrationToken,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
      "body": JSON.stringify({
        "password": this.state.formValue.password,
        "firstName": this.state.formValue.firstName,
        "lastName": this.state.formValue.lastName,
        "email": this.state.formValue.email
      })
    })
    .then(function(response) {
      if (response.ok) {
        alert("Account Successfully Created");
        that.props.history.push('/Login');
        console.log("Account Successfully Created");
      }
      else {
        throw response;
      }
    })
    .catch(function(err) {
      console.log(err);
      alert(err);
    });
   
    event.preventDefault();
  }

  // Render function
  render() {
    const { formValue } = this.state;
    if (store.get('loggedIn') === true) { // If the user is already logged in, redirect them to the files page.
      return <Redirect to="/Files" />;
    }

    return (
      <div className='show-container'>
        <Container>
          <Header>
            <Logo type='banner'/>
          </Header>
          <Content className="d-flex flex-column justify-content-center">
            <div className="Login-Title">
              Register for an Account
            </div>
            <div className="row justify-content-center pb-4">
              <div className="justify-content-left w-50">
                <div className="Register-Step"><span>Step 1 - </span>Contact Support to Receive a Registration Token</div>
                <div className="Register-Sub-Step">(Include your company name and full name to request a registration token from Customer Support.)</div>
              </div>
            </div>
            <div className="row justify-content-center pb-4">
              <a href="mailto:support@is5com.com?subject=Customer Portal Registration&body=Hello,%0D%0AI would like to register for an account with the iS5 Communications Customer Portal.%0D%0A%0D%0AFull Name: <First and Last>%0D%0ACompany Name: <Full Company Name>%0D%0AEmail Address: <Your email address>%0D%0A%0D%0AThank you." target="_blank" rel="noopener noreferrer"><button className="Sign-In-Button" type='button'>Email Support</button></a>
            </div>
            <div className="row justify-content-center pb-4">
              <div className="justify-content-left w-50">
                <div className="Register-Step"><span>Step 2 - </span>Have Your Registration Token Already?</div>
                <div className="Register-Sub-Step">Fill in the below form to finalize your registration:</div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6" id="Form">
                <Form 
                  ref={ref => (this.form = ref)}
                  onChange={formValue => {
                    this.setState({ formValue });
                  }}
                  onCheck={formError => {
                    this.setState({ formError });
                  }}
                  formValue={formValue}
                  model={this.state.model}
                >
                  <FormGroup>
                    <label className="col-sm-4 text-left">Registration Token:</label>
                    <FormControl name="registrationToken"/>
                    <HelpBlock tooltip>Note: The registration token is case-sensitive.</HelpBlock>
                  </FormGroup>

                  <FormGroup>
                    <label className="col-sm-4 text-left">Email:</label>
                    <FormControl name="email"/>
                    <HelpBlock tooltip>You must use the same email address as the one that was used to create the registration token.</HelpBlock>
                  </FormGroup>
                 
                  <FormGroup>
                    <label className="col-sm-4 text-left">First Name:</label>
                    <FormControl name="firstName" />
                  </FormGroup>

                  <FormGroup>
                    <label className="col-sm-4 text-left">Last Name:</label>
                    <FormControl name="lastName" />
                  </FormGroup>

                  <FormGroup>
                    <label className="col-sm-4 text-left">Password:</label>
                    <FormControl name="password" type="password" />
                    <HelpBlock tooltip>Password must meet the following requirements: minimum length of 8 characters, no whitespace characters, at least 1 lower-case letter, at least 1 upper-case letter, at least 1 number, and at least 1 special character: (! @ # $ % ^ & * ( ) : ; , . &lt; &gt; / ? _ - + =)</HelpBlock>
                  </FormGroup>

                  <FormGroup>
                    <label className="col-sm-4 text-left">Confirm Password:</label>
                    <FormControl name="passwordConfirmation" type="password" />
                    <HelpBlock tooltip>Please enter the same password as above.</HelpBlock>
                  </FormGroup>

                  <div className="d-flex flex-row justify-content-center">
                    <button className="Sign-In-Button" type='button' onClick={this.handleSubmit}>
                      Submit
                    </button>
                    <button className="Cancel-Button" type='button' onClick={this.handleClose}>
                      Cancel
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </Content>
        </Container>
      </div>
    );
  }
}
export default withRouter(UserSignUpPage);
