import React from 'react';
import store from 'store';
import '../styles/CustomerPortal.css';
import {
  Redirect,
  withRouter,
} from "react-router-dom";
import NavigationBar from './NavigationBar.js';
import 'rsuite/dist/styles/rsuite-default.css';
import RegisterUserPopup from './RegisterUserPopup.js';
import CreateUserButton from '../component_functions/CreateUserButton.js';
import { Container, Header, Content, Modal, FlexboxGrid, Table, Button, ControlLabel, ButtonToolbar, DatePicker, } from 'rsuite';
const { Column, HeaderCell, Cell } = Table;

// Add the Expire plugin to store.js:
var expirePlugin = require('store/plugins/expire');
store.addPlugin(expirePlugin);
const ONE_HOUR_MS = 60*60*1000;

class ManageUsersPage extends React.Component {
  // Constructor which receives our props
  constructor(props) {
    super(props);
    
    const endOfDay = new Date();
    endOfDay.setHours(23,59,59,0);

    const registerUserFormValue = {
        company: '',
        email: '',
        role: '',
    };
    // Next we set our state
    this.state = {
      userInfo: {},
      password: '',
      token: '',
      name: '',
      email: '',
      permissions: '',
      registerUserFormValue: registerUserFormValue,
      registerUserFormError: {},
      showRegisterUserPopup: false,
      popupOverflow: true,
      popupRows: 0,
      userTreeStructure: [],
      companyList: [],
      userList: [],
      userFiles: [],
      stateInfo: {},
      showSharePopup: false,
      permissionPopupRows: 0,
      submitPermissionButtonStatus: true,
      openPermissionPopupButtonStatus: true,
      selectedFileInfo: {},
      listOfUsersFilePermissions: [],
      endOfDate: endOfDay,
      filePermissionTreeStructure: [],
      userInfoString: '',
      grantPermissionForm: {
        user: {
          email: '',
        },
        file: {
          id: '',
          fileName: '',
        },
        endDate: endOfDay,
      },
      companyTemplate: 
      {
        id: '',
        company: '',
        children: [], // The children will be generated using the user template.
      },
      userTemplate: // Template to store/display information about a user in the user table.
      {
        id: '',
        name: '',
        email: '',
        companyName: '',
        role: '',
      },
      filesTreeStructure: [],
      fileInfo: {},
      productTemplate: 
      {
        id: '',
        labelName: '',
        children: [], // The children will be generated using the productVersionTemplate.
      },
      productVersionTemplate:
      {
        id: '',
        labelName: '', // Product Version Number
        children: [], // Files corresponding to the product version number
      },
      fileTemplate: {
        id: '', // ID associated with it's location in the table
        labelName: '', // the file name
        releaseDate: '',
        fileId: '', // Unique FileID used by the backend Server to identify the file.
        fileType: '',
        product: '',
        productVersion: '',
      },
    }
    this.handleLogout = this.handleLogout.bind(this);
    this.handleManageUsers = this.handleManageUsers.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.resetRows = this.resetRows.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRegisterNewUser = this.handleRegisterNewUser.bind(this);
    this.handleFilesPage = this.handleFilesPage.bind(this);
    this.updateStateExpiration = this.updateStateExpiration.bind(this);
    this.updateUserList = this.updateUserList.bind(this);
    this.updateFilesList = this.updateFilesList.bind(this);
    this.getTreeIndex = this.getTreeIndex.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.openPermissionPopup = this.openPermissionPopup.bind(this);
    this.resetPermissionRows = this.resetPermissionRows.bind(this);
    this.closePermissionPopup = this.closePermissionPopup.bind(this);
    this.updatePermissionEndDate = this.updatePermissionEndDate.bind(this);
    this.submitNewFilePermission = this.submitNewFilePermission.bind(this);
    this.getUsersFiles = this.getUsersFiles.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
  }

  // Method which calls the "file/all" endpoint to get the list of files that the current user has permission to download.
  getUsersFiles(email, token) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;

    // Attempt to get the list of files that the current user has permission to download.
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/file/all?userEmail=${email}`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "GET"
      }
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      }
      else {
        throw response;
      }
    })
    .then(function(response) {
      // 1: Parse the list of files to a usable list which can be displayed.
      var tempTreeStructure = [];
      var i;
      for (i = 0; i < response.length; i++) {
        var file = response[i];
        // If the user already has permission to view this file, skip this file.
        if (that.state.listOfUsersFilePermissions.includes(file.fileName)) {
          continue;
        }
        if (that.getTreeIndex(tempTreeStructure, file.product.name) === -1) { // If the current product has not yet been created, do so now.
          var tempProdTemplate = JSON.parse(JSON.stringify(that.state.productTemplate));
          var tempProdId = tempTreeStructure.length + 1;
          tempProdTemplate.id = tempProdId.toString();
          tempProdTemplate.labelName = file.product.name;
          tempTreeStructure.push(tempProdTemplate);
        }
        // Find index of the current product in the tempTreeStructure array.
        var rootIdx = that.getTreeIndex(tempTreeStructure, file.product.name);
        var rootIdxStr = (rootIdx + 1).toString();

        // If the current version number has not yet been created for the product, do so now.
        if (that.getTreeIndex(tempTreeStructure[rootIdx].children, file.productVersion) === -1) {
          var tempProdVersionTemplate = JSON.parse(JSON.stringify(that.state.productVersionTemplate));
          var tempVersionId = tempTreeStructure[rootIdx].children.indexOf(file.productVersion) + 1;
          tempProdVersionTemplate.id = rootIdxStr + '-' + tempVersionId.toString();
          tempProdVersionTemplate.labelName = file.productVersion;

          // Add the tempProdTemplate to the tree structure under the correct product name.
          tempTreeStructure[rootIdx].children.push(tempProdVersionTemplate);
        }

        // Now the tree structure is prepared to accept the file entries.
        // 1: Find the index of the product name in the tempTreeStructure - Known - rootIdx
        // 2: Find the index of the product number in the tempTreeStructure
        var prodNumberIdx = that.getTreeIndex(tempTreeStructure[rootIdx].children, file.productVersion);
        // 3: Create a new file template object
        var tempFileTemplate = JSON.parse(JSON.stringify(that.state.fileTemplate));
        // 4: Find the index where the new file will be located.
        var fileIdx = tempTreeStructure[rootIdx].children[prodNumberIdx].children.length;
        var fileIdxStr = (fileIdx + 1).toString();  // The index string used by the table to identify the file.
        // 5: Add data to the file template object
        tempFileTemplate.id = tempTreeStructure[rootIdx].children[prodNumberIdx].id + "-" + fileIdxStr;
        tempFileTemplate.labelName = file.fileName;
        tempFileTemplate.releaseDate = file.releaseDate.split("T")[0];
        tempFileTemplate.fileId = file.id;
        tempFileTemplate.fileType = file.fileType;
        tempFileTemplate.product = file.product.name;
        tempFileTemplate.productVersion = file.productVersion;
        // 6: Add the new populated file tempate object to the tempTreeStructure
        tempTreeStructure[rootIdx].children[prodNumberIdx].children.push(tempFileTemplate);
      }
      // Once all of the downloadable files have been iterated through,
      // 2: push the new list to update the state filesTreeStructure
      that.setState({ filePermissionTreeStructure: tempTreeStructure });
    })
    .catch(function(err) {
      console.log(err);
      alert('File List Update Failed');
    });
  }

  submitNewFilePermission(grantPermissionForm) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;
    var token = that.state.stateInfo.token;

    // Attempt to get the list of files that the current user has permission to download.
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/file/permit`, {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
      "body": JSON.stringify(grantPermissionForm),
    })
    .then(function(response) {
      if (response.ok) {
        // Alert user of success.
        alert("File Shared");
        // Close pop-up.
        that.closePermissionPopup();
      }
      else {
        throw response;
      }
    })
    .catch(function(err) {
      console.log(err);
      alert('Share Failed');
    });
  }

  updatePermissionEndDate(newEndDate) {
    newEndDate.setHours(23,59,59,0);  // Set the permission to end after the last second of the last minute of the last hour of the day.
    var updatedGrantPermissionForm = JSON.parse(JSON.stringify(this.state.grantPermissionForm));
    updatedGrantPermissionForm.endDate = newEndDate;
    this.setState({ grantPermissionForm: updatedGrantPermissionForm });
  }

  closePermissionPopup() {
    // Reset the data in the grant permission form.
    var tempGrantPermissionForm = { user: { email: this.state.userInfo.email }, file: { id: '', fileName: '' }, endDate: this.state.endOfDate };
    this.setState({ showSharePopup: false, grantPermissionForm: tempGrantPermissionForm });
    // Update the list of file permissions that the currently selected user has.
    this.updateFilesList(this.state.userInfo.email, this.state.stateInfo.token);
  }

  resetPermissionRows() {
    this.setState({ permissionPopupRows: 0 });
  }

  openPermissionPopup(event) {
    this.getUsersFiles(this.state.stateInfo.email, this.state.stateInfo.token);
    var tempGrantPermissionForm = { user: { email: this.state.userInfo.email }, file: { id: '', fileName: ''}, endDate: this.state.endOfDate };
    this.setState({ showSharePopup: true, grantPermissionForm: tempGrantPermissionForm });
    setTimeout(() => {
      this.setState({
        permissionPopupRows: 80
      });
    }, 2000);
  }

  deleteUser(email) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;

    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/user/delete/${email}`, {
      "method": "DELETE",
      "headers": {
        "Content": "text/plain",
        "Content-Type": "text/plain",
        "Authorization": "Bearer " + this.state.token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
    })
    .then(function(response) {
      if (response.ok) {
        alert("User successfully deleted");
        that.updateUserList(that.state.stateInfo.token);
      } else {
        throw response;
      }
    })
    .catch(function(err) {
      console.error(err);
      alert('User coult not be deleted');
    });
  }

  handleDeleteUser(event) {
    let doDelete = window.confirm("Are you sure you want to delete " + this.state.userInfo.email);
    if (doDelete) {
      this.deleteUser(this.state.userInfo.email);
    } // else clicked cancel, do not delete

  }

  // Method which calls the "user/info" endpoint to get the current user's info such as company, first and last name, email and role.
  getUserInfo(email, token) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;

    // Attempt to get the list of files that the current user has permission to download.
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/user/info`, {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
      "body": JSON.stringify({
        "email": email,
      }),
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      }
      else {
        throw response;
      }
    })
    .then(function(response) {
      // If the current session is not timed out
      var state = store.get('state');
      var authToken = store.get('authenticationTokenExpiration');
      if (authToken !== undefined) {
        var tempStateInfo = {
          email: response.email,
          firstName: response.firstName,
          lastName: response.lastName,
          company: response.company,
          role: response.role,
          token: state.authenticationToken,
        };
        that.setState({ stateInfo: tempStateInfo });
        var expirationTime = store.get('authenticationTokenExpiration').expiration;
        store.set('stateInfo', tempStateInfo, expirationTime);
      }
    })
    .catch(function(err) {
      console.log(err);
      alert('User Info Failed To Load');
    });
  }

  getTreeIndex(treeStructure, entry) {
    return treeStructure.findIndex(x => x.labelName === entry);
  }

  // Method which calls the "file/all" endpoint to get the list of files that the current user has permission to download.
  updateFilesList(email, token) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;

    // Attempt to get the list of files that the current user has permission to download.
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/file/all?userEmail=${email}`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "GET"
      }
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      }
      else {
        throw response;
      }
    })
    .then(function(response) {
      // 1: Parse the list of files to a usable list which can be displayed.
      var tempTreeStructure = [];
      var tempFileList = [];
      var i;
      for (i = 0; i < response.length; i++) {
        var file = response[i];
        tempFileList.push(file.fileName);
        if (that.getTreeIndex(tempTreeStructure, file.product.name) === -1) { // If the current product has not yet been created, do so now.
          var tempProdTemplate = JSON.parse(JSON.stringify(that.state.productTemplate));
          var tempProdId = tempTreeStructure.length + 1;
          tempProdTemplate.id = tempProdId.toString();
          tempProdTemplate.labelName = file.product.name;
          tempTreeStructure.push(tempProdTemplate);
        }
        // Find index of the current product in the tempTreeStructure array.
        var rootIdx = that.getTreeIndex(tempTreeStructure, file.product.name);
        var rootIdxStr = (rootIdx + 1).toString();

        // If the current version number has not yet been created for the product, do so now.
        if (that.getTreeIndex(tempTreeStructure[rootIdx].children, file.productVersion) === -1) {
          var tempProdVersionTemplate = JSON.parse(JSON.stringify(that.state.productVersionTemplate));
          var tempVersionId = tempTreeStructure[rootIdx].children.indexOf(file.productVersion) + 1;
          tempProdVersionTemplate.id = rootIdxStr + '-' + tempVersionId.toString();
          tempProdVersionTemplate.labelName = file.productVersion;

          // Add the tempProdTemplate to the tree structure under the correct product name.
          tempTreeStructure[rootIdx].children.push(tempProdVersionTemplate);
        }

        // Now the tree structure is prepared to accept the file entries.
        // 1: Find the index of the product name in the tempTreeStructure - Known - rootIdx
        // 2: Find the index of the product number in the tempTreeStructure
        var prodNumberIdx = that.getTreeIndex(tempTreeStructure[rootIdx].children, file.productVersion);
        // 3: Create a new file template object
        var tempFileTemplate = JSON.parse(JSON.stringify(that.state.fileTemplate));
        // 4: Find the index where the new file will be located.
        var fileIdx = tempTreeStructure[rootIdx].children[prodNumberIdx].children.length;
        var fileIdxStr = (fileIdx + 1).toString();  // The index string used by the table to identify the file.
        // 5: Add data to the file template object
        tempFileTemplate.id = tempTreeStructure[rootIdx].children[prodNumberIdx].id + "-" + fileIdxStr;
        tempFileTemplate.labelName = file.fileName;
        tempFileTemplate.releaseDate = file.releaseDate.split("T")[0];
        tempFileTemplate.fileId = file.id;
        tempFileTemplate.fileType = file.fileType;
        tempFileTemplate.product = file.product.name;
        tempFileTemplate.productVersion = file.productVersion;
        // 6: Add the new populated file tempate object to the tempTreeStructure
        tempTreeStructure[rootIdx].children[prodNumberIdx].children.push(tempFileTemplate);
      }
      // Once all of the downloadable files have been iterated through,
      // 2: push the new list to update the state filesTreeStructure
      that.setState({ filesTreeStructure: tempTreeStructure, listOfUsersFilePermissions: tempFileList });
    })
    .catch(function(err) {
      console.log(err);
      alert('File List Update Failed');
    });
  }

  updateUserList(token) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;

    // Update session timeout
    that.updateStateExpiration();

    // Attempt to get the list of files that the current user has permission to download.
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/user/all`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "GET"
      },
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      }
      else {
        throw response;
      }
    })
    .then(function(response) {
      // 1: Parse the list of users into a usable structure which can be displayed.
      var tempCompanyList = [];
      var userEmailList = []
      var tempUserTreeStructure = [];
      var idx;
      for (idx = 0; idx < response.length; idx++) {
        let currentUser = response[idx];
        // If the user's company is not in the list of companies, add the company to the list of companies.
        if (!tempCompanyList.includes(currentUser.company)) {
          tempCompanyList.push(currentUser.company);
          var newCompanyId = tempCompanyList.indexOf(currentUser.company) + 1;
          var newCompany = JSON.parse(JSON.stringify(that.state.companyTemplate));
          newCompany.id = newCompanyId;
          newCompany.company = currentUser.company;
          tempUserTreeStructure.push(newCompany);
        }

        // Find the index of the current user's company in the user tree structure.
        var rootIdx = tempUserTreeStructure.findIndex(x => x.company === currentUser.company);
        var rootIdxStr = (rootIdx + 1).toString();

        // So long as the current user does not exist, create a new entry for them and add them to the tempUserTreeStructure.
        if (!userEmailList.includes(currentUser.email)) {
          userEmailList.push(currentUser.email);
          var userIdx = tempUserTreeStructure[rootIdx].children.length;
          var userIdxStr = (userIdx + 1).toString();
          var newUser = JSON.parse(JSON.stringify(that.state.userTemplate));
          newUser.id = rootIdxStr + "-" + userIdxStr;
          newUser.email = currentUser.email;
          newUser.name = currentUser.firstName + " " + currentUser.lastName;
          newUser.role = currentUser.role;
          newUser.companyName = currentUser.company;
          tempUserTreeStructure[rootIdx].children.push(newUser);
        }
      }
      // Once all of the users have been iterated through, update the current state's user tree structure and the list of companies.
      that.setState({ userTreeStructure: tempUserTreeStructure });
    })
    .catch(function(err) {
      console.log(err);
      alert('User List Update Failed');
    });

    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/company/all`, {
      "method": "GET",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "GET"
      },
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      }
      else {
        throw response;
      }
    })
    .then(function(response) {
      var tempCompanyList = [];
      var idx;
      for (idx = 0; idx < response.length; idx++) {
        let company = response[idx];
        tempCompanyList.push({ label: company.name, value: company.name });
      }
      that.setState({ companyList: tempCompanyList});

    })
    .catch(function(err) {
      console.log(err);
      alert('Company List Update Failed');
    });

  }

  updateStateExpiration() {
    var state = store.get('state');
    if (state !== undefined) {
      var updatedExpiration = new Date().getTime() + ONE_HOUR_MS;
      var tempStateInfo = store.get('stateInfo');
      if (tempStateInfo === undefined) {
        this.getUserInfo(state.email, state.authenticationToken);
        tempStateInfo = store.get('stateInfo');
      }
      if (updatedExpiration <= store.get('authenticationTokenExpiration').expiration) {
        store.set('state', state, updatedExpiration);
        store.set('loggedIn', true, updatedExpiration);
        store.set('stateInfo', tempStateInfo, updatedExpiration);
      }
      else {
        store.set('state', state, store.get('authenticationTokenExpiration').expiration);
        store.set('loggedIn', true, store.get('authenticationTokenExpiration').expiration);
        store.set('stateInfo', tempStateInfo, store.get('authenticationTokenExpiration').expiration);
      }
    }
  }

  close() {
    this.setState({ showRegisterUserPopup: false });
  }

  resetRows() {
    this.setState({ popupRows: 0 });
  }

  open(event) {
    this.setState({ showRegisterUserPopup: true });
    setTimeout(() => {
      this.setState({
        popupRows: 80
      });
    }, 2000);
  }

  handleRegisterNewUser(formValue) {
    // Since the definition of 'this' changes depending on scope of functions and classes, save 'this' as 'that' and use 'that' in the fetch function to update state of the LoginPage class.
    const that = this;
    // Update session timeout
    that.updateStateExpiration();
    // Attempt to authenticate the entered user
    fetch(`${process.env.REACT_APP_SCHEME}://${process.env.REACT_APP_RESTAPI_SERVER}:${process.env.REACT_APP_RESTAPI_SERVER_PORT}/user/register/token`, {
      "method": "POST",
      "headers": {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + this.state.token,
        "Access-Control-Request-Headers": "X-Requested-With",
        "Access-Control-Request-Method": "POST"
      },
      "body": JSON.stringify(formValue),
    })
    .then(function(response) {
      if (response.ok) {
        return response.json();
      } else {
        throw response;
      }
    })
    .then(function(responseToken) {
      // Update the list of users.
      that.updateUserList(that.state.stateInfo.token);
      // Close the pop-up window
      that.close();
      // Notify the user of the new user's registration token.
      alert("New User Registration Token is: " + responseToken);
    })
    .catch(function(err) {
      console.log(err);
      alert('Registration Failed: Invalid Email or Company Name');
    });
  }
  
  handleManageUsers() {
    this.props.history.push('/ManageUsers');
  }

  handleFilesPage() {
    this.props.history.push('/Files');
  }

  componentDidMount() {
    // If user has timed out, sign them out.
    if (store.get('authenticationTokenExpiration') === undefined) {
      this.handleLogout();
    } else {
      if (store.get('stateInfo') === undefined) { // If the user has just logged in and the stateInfo has not yet been set, set the stateInfo.
        this.getUserInfo(store.get('state').email, store.get('state').authenticationToken);
      } else {
        this.setState({ stateInfo: store.get('stateInfo') });
      }
      this.updateStateExpiration();
    }
    try {
      if (store.get('stateInfo') !== undefined) {
        if (this.state.email === '') {
          this.setState({ email: store.get('stateInfo').email, token: store.get('stateInfo').token, role: store.get('stateInfo').role });
        }
        this.updateUserList(store.get('stateInfo').token);
      }
    } catch (err) {
        console.log(err);
    }
  }

  // Function to update states when text in text boxes is changed
  handleChange(changeObject) {
    this.setState(changeObject);
  }

  handleLogout() {
    store.remove('loggedIn');
    store.remove('state');
    store.remove('authenticationTokenExpiration');
    store.remove('stateInfo');
    this.props.history.push('/Login');
  }

  render() {
    if (store.get('loggedIn') !== true) {    // If the user is not logged in, redirect them to the login page
      return <Redirect to="/Login" />;
    } else if (store.get('stateInfo').role !== 'Admin' && store.get('stateInfo').role !== 'Maintainer') {
      // If the user is not authorized to view this page, navigate them back to the My Files page.
      return <Redirect to='/Files' />;
    }
    const { showRegisterUserPopup } = this.state;
    return (
      <div className='show-container'>
      <div className='modal-container'>
        <Container>
          <Header>
            <div className='nav-wrapper'>
              <NavigationBar displayName={this.state.stateInfo.email} onLogout={this.handleLogout} onManageUsers={this.handleManageUsers} onFilesPage={this.handleFilesPage} userRole={this.state.stateInfo.role} />
            </div>
          </Header>
          <Container>
            <Header>
              <h1 className="Page-Title">
                Manage Users
              </h1>
            </Header>

            <Modal show={showRegisterUserPopup} onHide={this.close} onExited={this.resetRows}>
              <Modal.Header>
                <Modal.Title>Invite New User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <RegisterUserPopup handleRegisterNewUser={this.handleRegisterNewUser} handleChange={this.handleChange} handleClose={this.close} companyList={this.state.companyList} />
              </Modal.Body>
            </Modal>

            <Modal show={this.state.showSharePopup} onHide={this.closePermissionPopup} onExited={this.resetPermissionRows}>
              <Modal.Header>
                <Modal.Title>Share</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ControlLabel className="Date-Picker-Label"><div className="Title">User: </div>{this.state.userInfoString}</ControlLabel>
                <ControlLabel className='Form-Input-Label Title'>File: </ControlLabel>
                <div className="File-Info-Border">
                  <div className="File-Info-Border-Padding">
                    <Table data={this.state.filePermissionTreeStructure} isTree rowKey='id' height={275}
                      onRowClick={rowData => {
                        const that = this;
                        var tempFormData;
                        if (rowData.hasOwnProperty('releaseDate')) {
                          // Enable the grant permission button and store the selected file's information somewhere so that when the submit button is pressed, the fetch call will know what file to grant permission for.
                          that.setState({ selectedFileInfo: rowData, submitPermissionButtonStatus: false });
                          tempFormData = JSON.parse(JSON.stringify(that.state.grantPermissionForm));
                          tempFormData.file.fileName = rowData.labelName;
                          tempFormData.file.id = rowData.fileId;
                          that.setState({ grantPermissionForm: tempFormData });
                        } else {
                          // Disable the grant permission button
                          that.setState({ selectedFileInfo: {}, submitPermissionButtonStatus: true });
                          tempFormData = JSON.parse(JSON.stringify(that.state.grantPermissionForm));
                          tempFormData.file.fileName = '';
                          tempFormData.file.id = '';
                          that.setState({ grantPermissionForm: tempFormData });
                        }
                      }}
                    >
                      <Column flexGrow={1}>
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey='labelName'/>
                      </Column>
                      <Column width={100}>
                        <HeaderCell>Release Date</HeaderCell>
                        <Cell dataKey='releaseDate'/>
                      </Column>
                    </Table>
                  </div>
                </div>
                <ControlLabel className="Date-Picker-Label Title">Expires: </ControlLabel>
                <div className="Date-Picker">
                  <DatePicker oneTap className="Form-Input" placement="autoVerticalStart" value={this.state.grantPermissionForm.endDate} onChange={((e) => this.updatePermissionEndDate(e))}/>
                </div>
                <ButtonToolbar>
                  <Button onClick={(e) => this.submitNewFilePermission(this.state.grantPermissionForm)} disabled={this.state.submitPermissionButtonStatus} appearance='primary'>
                    Share
                  </Button>
                  <Button onClick={this.closePermissionPopup}>
                    Cancel
                  </Button>
                </ButtonToolbar>
              </Modal.Body>
            </Modal>

            <div className='App-Container'>
              <Content className='Page-Content'>
                  <div className='Manage-Users-Table'>
                  <FlexboxGrid justify="space-around" className='Page-Grid'>
                    <FlexboxGrid.Item colspan={7}>
                      <h3>User List</h3>
                      <div className="File-Info-Border">
                        <div className="File-Info-Border-Padding">
                          <Table data={this.state.userTreeStructure} isTree rowKey='id' height={275}
                            onRowClick={rowData => {
                              const that = this;
                              if (rowData.hasOwnProperty('email')) {
                                var tempUserInfoString = rowData.companyName + " > " + rowData.email;
                                that.setState({ userInfo: rowData, userInfoString: tempUserInfoString });
                                that.updateFilesList(rowData.email, that.state.token);
                                if (rowData.role === "Customer") {  // If the selected user is a Customer, enable the grant new file permission button.
                                  that.setState({ openPermissionPopupButtonStatus: false });
                                } else {  // If the selected user is not a Customer, disable the grant new file permission button.
                                  that.setState({ openPermissionPopupButtonStatus: true });
                                }
                              } else {
                                that.setState({ userInfo: {}, openPermissionPopupButtonStatus: true, userInfoString: '' });
                              }
                            }}
                          >
                            <Column flexGrow={1}>
                              <HeaderCell>Company</HeaderCell>
                              <Cell dataKey='company'/>
                            </Column>
                            <Column flexGrow={1}>
                              <HeaderCell>Name</HeaderCell>
                              <Cell dataKey='name'/>
                            </Column>
                            <Column flexGrow={1}>
                              <HeaderCell>Email</HeaderCell>
                              <Cell dataKey='email'/>
                            </Column>
                          </Table>
                        </div>
                      </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={7}>
                      <h3>User Information</h3>
                      <div className="File-Info-Border">
                        <div className="File-Info-Border-Padding">
                          <div className='File-Info-Content'>
                            <h6>Name: <p className='File-Info-Entries'>{this.state.userInfo.name}</p></h6>
                            <h6>Company: <p className='File-Info-Entries'>{this.state.userInfo.companyName}</p></h6>
                            <h6>Email: <p className='File-Info-Entries'>{this.state.userInfo.email}</p></h6>
                            <h6>Role: <p className='File-Info-Entries'>{this.state.userInfo.role}</p></h6>
                          </div>
                        </div>
                      </div>
                      <CreateUserButton open={this.open} userRole={this.state.stateInfo.role} />
                      <Button className='Download-Button' appearance='link' onClick={this.openPermissionPopup} disabled={this.state.openPermissionPopupButtonStatus}>Share</Button>
                      <Button className='Download-Button' appearance='link' onClick={this.handleDeleteUser} disabled={this.state.openPermissionPopupButtonStatus}>Delete</Button>
                      
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={7}>
                      <h3>User Files</h3>
                      <div className="File-Info-Border">
                        <div className="File-Info-Border-Padding">
                          <Table data={this.state.filesTreeStructure} isTree rowKey='id' height={275}
                            onRowClick={rowData => {
                              console.log(rowData);
                            }}
                          >
                            <Column flexGrow={1}>
                              <HeaderCell>Name</HeaderCell>
                              <Cell dataKey='labelName'/>
                            </Column>
                            <Column width={100}>
                              <HeaderCell>Release Date</HeaderCell>
                              <Cell dataKey='releaseDate'/>
                            </Column>
                          </Table>
                        </div>
                      </div>
                    </FlexboxGrid.Item>
                  </FlexboxGrid> 
                  </div>
              </Content>
            </div>
          </Container>
        </Container>
      </div>
      </div>
    )
  }
}
export default withRouter(ManageUsersPage);
